@import "../../shared/scss/base";

.display {
  font-size: 72px;
  font-weight: 600;
  line-height: 74px;
  letter-spacing: -0.025em;
}

.body {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $text-primary;
}

.small {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: $text-primary;
}

.bold {
  font-weight: 600;
}

.whiteText {
  color: $Marshmallow;
}
