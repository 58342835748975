@import "./_cms.scss";
@import "./_colors.scss";
@import "./_helpers.scss";
@import "./_media-queries.scss";
@import "./_normalize.scss";
@import "./_sizes.scss";

:global .display-xs {
  display: none !important;

  @include media($min-xs) {
    display: block !important;
  }
}

:global .display-sm {
  display: none !important;

  @include media($min-sm) {
    display: block !important;
  }
}

:global .display-md {
  display: none !important;

  @include media($min-md) {
    display: block !important;
  }
}

:global .display-lg {
  display: none !important;

  @include media($min-lg) {
    display: block !important;
  }
}

:global .display-flex-sm {
  display: none !important;

  @include media($min-sm) {
    display: flex !important;
  }
}

:global .display-flex-md {
  display: none !important;

  @include media($min-md) {
    display: flex !important;
  }
}

:global .display-flex-lg {
  display: none !important;

  @include media($min-lg) {
    display: flex !important;
  }
}

:global .hide-sm {
  @include media($min-sm) {
    display: none !important;
  }
}

:global .hide-md {
  @include media($min-md) {
    display: none !important;
  }
}

:global .hide-lg {
  @include media($min-lg) {
    display: none !important;
  }
}

:global .display-inline-xs {
  display: none !important;

  @include media($min-xs) {
    display: inline !important;
  }
}

:global .display-inline-sm {
  display: none !important;

  @include media($min-sm) {
    display: inline !important;
  }
}

:global .display-inline-md {
  display: none !important;

  @include media($min-md) {
    display: inline !important;
  }
}

:global .display-inline-lg {
  display: none !important;

  @include media($min-lg) {
    display: inline !important;
  }
}

:global .cms-content {
  ul,
  ol {
    all: revert;
  }
}

#asapp-chat-sdk-iframe.asappChatSDKIFrame_opened {
  z-index: 9999;
}
