@import "./../../shared/scss/base";

.filter-result {
  margin: $space-lg $space-md;

  .title {
    margin: 0 0 $space-md;
  }

  @media (min-width: $screenSm) {
    .title {
      margin-bottom: $space-xxs;
    }
  }
}
