@import "./../../shared/scss/base";

.accessories-banner {
  flex-direction: row;
  gap: $space-md;

  &:hover {
    background-color: $Smoke;
  }

  .banner-title {
    margin-bottom: $space-xxs / 2;
  }

  .accessories-link {
    color: $link-primary;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  &.mobile-version {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: row;
    max-width: unset;
    width: 100%;

    .banner-text__wrapper {
      flex: 1;
    }

    .banner-image__wrapper {
      justify-content: space-between;
    }

    @include media($min-md) {
      display: none;
    };
  }

  &.desktop-version {
    display: none;
    padding: $space-lg;

    .banner-text__wrapper {
      .banner-title {
        margin-bottom: 8px;
      }
    }

    .banner-image__wrapper {
      flex: 1;
      justify-content: center;
    }

    @include media($min-md) {
      display: flex;
      flex-direction: column;
      gap: $space-md;
    }
  }

  &.mobile-version.active,
  &.desktop-version.active {
    display: flex;
  }

  &.mobile-version.active {
    @include media($min-sm) {
      justify-content: space-between;

      .banner-text__wrapper {
        max-width: 286px;
      }
    }
  }

  .banner-image__wrapper {
    display: flex;
    gap: $space-md;

    .product-image {
      margin: 0;
      mix-blend-mode: multiply
    }
  }
}

.product-card-tile-group {
  .product-card.accessories-banner{
    &.desktop-version{
      padding: $space-lg;
    }
    &.mobile-version{
      flex-direction: row;
      max-width: unset;
    }
  }
}