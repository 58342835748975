@import "../../shared/scss/base";

%button {
  justify-content: center;
  padding: 11px 28px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  gap: 8px;

  > p {
    display: flex;
    height: 24px;
    align-items: center;
  }

  &:disabled {
    cursor: default;
  }
}

.cds--btn.cds--btn--primary {
  @extend %button;
  background-color: $button-primary;

  > p {
    color: $text-on-color;
  }

  > svg {
    fill: $text-on-color;
  }

  &:hover {
    background: $button-primary-hover;

    > p {
      color: $text-on-color;
    }

    > svg {
      fill: $text-on-color;
    }
  }

  &:active {
    background: $button-primary-active;

    > p {
      color: $text-on-color;
    }

    > svg {
      fill: $text-on-color;
    }
  }

  &:focus {
    background: $button-primary;

    > p {
      color: $text-on-color;
    }

    > svg {
      fill: $text-on-color;
    }
  }

  &:disabled {
    background: $button-disabled;

    > p {
      color: $text-on-color;
    }

    > svg {
      fill: $text-on-color;
    }
  }
}

.cds--modal-footer {
  .cds--btn.cds--btn--primary,
  .cds--btn.cds--btn--tertiary,
  .cds--btn.cds--btn--secondary {
    padding: 0;
  }
}

button.cds--btn.cds--btn--secondary {
  background-color: $button-secondary;
}

.cds--btn.cds--btn--secondary {
  @extend %button;
  background-color: $button-secondary;
  border: 1px solid $button-border;

  > p {
    color: $text-primary;
  }

  > svg {
    fill: $text-primary;
  }

  &:hover {
    background-color: $button-secondary-hover;
    border: 1px solid $button-border;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:active {
    background: $button-secondary-active;
    border: 1px solid $button-border;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:focus {
    background: $button-tertiary;
    border: 1px solid $button-border;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:disabled {
    border: 1px solid $button-disabled;

    > p {
      color: $text-disabled;
    }

    > svg {
      fill: $text-disabled;
    }
  }
}

.cds--btn.cds--btn--tertiary {
  @extend %button;
  background-color: $button-tertiary;
  border: none;

  > p {
    color: $text-primary;
  }

  > svg {
    fill: $text-primary;
  }

  &:hover {
    background: $button-tertiary-hover;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:active {
    background: $button-tertiary-active;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:focus {
    background: $button-tertiary;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:disabled {
    background: $button-tertiary;

    > p {
      color: $text-inverse;
    }

    > svg {
      fill: $text-inverse;
    }
  }
}

.cds--btn.cds--btn--ghost {
  @extend %button;
  background-color: $button-ghost;
  border: none;

  > p {
    color: $text-primary;
  }

  > svg {
    fill: $text-primary;
  }

  &:hover {
    background: $button-ghost-hover;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:active {
    background: $button-ghost-active;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:focus {
    background: $button-ghost;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:disabled {
    background: $button-ghost;

    > p {
      color: $text-disabled;
    }

    > svg {
      fill: $text-disabled;
    }
  }
}

.cds--btn.cds--btn--neutral {
  @extend %button;
  background-color: $button-neutral;
  border: none;

  > p {
    color: $text-primary;
  }

  > svg {
    fill: $text-primary;
  }

  &:hover {
    background: $button-neutral-hover;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:active {
    background: $button-neutral-active;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:focus {
    background: $button-neutral;

    > p {
      color: $text-primary;
    }

    > svg {
      fill: $text-primary;
    }
  }

  &:disabled {
    background: $button-disabled;

    > p {
      color: $text-inverse;
    }

    > svg {
      fill: $text-inverse;
    }
  }
}
