@import "../../../shared/scss/base";

.product-card-tile-group {
  flex-direction: row;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 16px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(155px, 193px));
  @media screen and (min-width: 520px) {
    grid-template-columns: repeat(2, minmax(235px, 258px));
  }
  @media screen and (min-width: 968px) {
    grid-template-columns: repeat(4, minmax(235px, 258px));
  }
  @media screen and (min-width: 1258px) {
    grid-template-columns: repeat(4, minmax(290px, max-content));
  }

  .product-card {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: -webkit-fill-available;
    background: $Smoke;
    padding: 16px;
    border: none;
    justify-content: space-between;
    max-width: 190px;
    border-radius: 8px;

    @media screen and (min-width: 430px) {
      max-width: 290px;
    }

    @media screen and (min-width: 1256px) {
      width: 290px;
    }

    .product-image {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 16px;
    }

    .phone-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      gap: 2px;
    }

    .action-btn {
      background: $Marshmallow;
      margin-bottom: 16px;
      padding: 8px 12px;
      text-align: center;
    }

    .pricing {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .price {
        display: flex;
        gap: 4px;
      }
    }
  }
}
