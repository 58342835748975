@import "../../shared/scss/base";

.buy-header {
  background-color: $Marshmallow;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  position: sticky;
  top: 0;
  z-index: 999;

  > button.cds--btn {
    max-width: fit-content;
    padding: 16px;
    > svg {
      fill: $Coal;
    }
    > p {
      color: $Coal;
    }
  }
}
