@import "./../../shared/scss/sizes";
@import "./../../shared/scss/colors";

.filter-criteria {
  align-items: flex-start;
  background: $Smoke;
  display: flex;
  flex-direction: column;
  gap: $space-xs;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: $space-md;
  }

  &__name {
    align-items: center;
    color: $Coal;
    display: flex;
    flex: 1 0 0;
    gap: 0;
    line-height: $line-height-sm * 1.75;
    padding: $space-md $space-md 0;
  }

  &__items-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $space-xxs / 2;
    list-style: none;
    margin: 0;
    padding: 0 $space-md $space-md $space-xs;

    .radio-button__element {
      display: flex;
      align-items: center;
    }

    .toggle-option__item {
      align-items: flex-start;
      cursor: pointer;
      display: flex;
      padding: $space-xs;

      .small {
        color: $Cinder;
      }
    }

    .filter-criteria__item {
      padding: $space-xs;

      .cds--radio-button:checked + .cds--radio-button__label {
        .cds--radio-button__appearance {
          border-color: $Spark;

          &::before {
            background-color: $Spark;
          }
        }
      }
    }
  }

  &.only-item__wrapper {
    align-items: center;
    align-self: stretch;
    background: $Smoke;
    border-radius: $space-xs;
    display: flex;
    flex-direction: row;
    gap: $space-md;
    padding: $space-md;

    .device-content {
      align-self: stretch;
      align-items: center;
      display: flex;
      flex: 1 0 0;
      gap: $space-md;
      padding: 0;

      .image__wrapper {
        align-items: center;
        display: flex;
        height: 42px;
        justify-content: center;
        width: 42px;
      }
    }
  }
}
