@import "./../../shared/scss/base";

.small-modal-container .filter-modal {
  .filter-modal__header {
    align-items: flex-start;
    align-self: stretch;
    background: $Marshmallow;
    border-radius: $border-radius-md $border-radius-md 0 0;
    display: flex;
    gap: $space-md;
    padding: $space-md $space-md 0;

    .header__wrapper {
      align-items: flex-start;
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: $space-xs;
      padding: $space-lg 0 $space-md 0;
    }

    .cross-icon__close {
      cursor: pointer;
    }
  }

  .filter-modal__content {
    margin-bottom: $space-xxs * 6.25;
    min-height: 490px;
    padding: $space-md;
  }

  .filter-modal__buttons {
    align-self: stretch;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: $space-xs;

    .reset-button {
      color: $Coal;
    }
  }

  .without-imgHeader-content {
    padding: 0 !important;
  }

  .cds--modal-container {
    width: 100%;
    border-radius: 16px 0 16px 0;

    @include media($min-sm) {
      max-width: 596px;
      border-radius: 16px;
    }
    .apply-button {
      &.cds--btn.cds--btn--primary {
        padding: 0.875rem 2rem;
      }
    }

    .reset-button {
      &.cds--btn.cds--btn--secondary {
        padding: 0.875rem 2rem;
      }
    }
  }
}
