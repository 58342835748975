@import "../../shared/scss/base";

.img {
  opacity: 0;
  transition: opacity 0.34s;
}

.img--loaded {
  opacity: 1;
}
