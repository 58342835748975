@import "../../shared/scss/base";

.footer-wrapper {
  background-color: $Smoke;

  footer {
    padding: 64px 16px;
    @media screen and (min-width: $screenSm) {
      padding: 64px 0;
      width: 80%;
      margin: auto;
    }

    .footer-links {
      display: flex;
      flex-direction: column;
      gap: 32px;

      svg {
        min-width: 32px;
        margin-bottom: 32px;
        margin-left: 0;
      }

      @media screen and (min-width: $screenSm) {
        flex-direction: row;
        align-items: flex-start;
        gap: 64px;

        svg {
          margin-bottom: 0;
        }
      }
    }

    .footer-label {
      display: flex;

      > p {
        display: flex;
        align-items: center;
      }

      .label-icon {
        align-self: center;
        padding-left: 8px;
      }
    }

    .social-and-legal {
      display: flex;
      flex-direction: column;
      padding-top: 64px;
      gap: 64px;

      @media screen and (min-width: $screenSm) {
        flex-direction: row;
      }

      .footer-social {
        display: flex;
        flex-direction: row;
        gap: 32px;

        svg {
          min-width: 25px;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }
}
