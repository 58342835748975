@import "../../shared/scss/base";

.mobile-nav {
  position: sticky;
  width: 100%;
  background: $Marshmallow;

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .menu-icon {
    cursor: pointer;
  }

  .open-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 48px;
    background-color: $Marshmallow;
    width: 100%;

    .menu-items {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 0;
      margin: 0;

      a {
        text-decoration: none;
        padding: 16px 12px;
        border-left: 4px solid transparent;
        &.active {
          border-left: 4px solid $Spark;
        }
        &:hover {
          cursor: pointer;
          background-color: $Smoke;
        }
        &:focus-visible {
          outline: 2px solid $Dark-Blue;
        }
      }
    }

    .menu-button {
      display: flex;
      margin: 32px 16px 16px 16px;
      max-width: -webkit-fill-available;
    }
  }

  .cds--link.link > p {
    color: $Coal;
  }
}
