@import "../../shared/scss/base";

.accessories-plp-page {
  display: flex;
  flex-direction: column;
  margin: 64px 0;

  @media screen and (min-width: $screenMd) {
    max-width: 1260px;
    margin: 64px auto;
  }

  .phone-filter {
    padding: 16px;
    background-color: $Smoke;
    flex-direction: column;

    @media screen and (min-width: $screenMd) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .filter-type {
      margin-bottom: 16px;

      @media screen and (min-width: $screenMd) {
        margin-top: 16px;
      }
    }

    .phone-os-group {
      @media screen and (min-width: $screenMd) {
        width: 392px;
      }
    }
  }

  .product-lists {
    margin: 32px 16px;
    @media screen and (min-width: $screenMd) {
      margin: 32px 0;
    }

    .no-products {
      text-align: center;
    }
  }

  .plp-header-container {
    margin-left: 16px;
  }

  .plp-filter-selector-container {
    margin: 32px 0 0 0;
    padding-bottom: 16px;

    .plp-sub-header {
      margin: 0 0 0 16px;
    }

    #filter-button {
      background: $Smoke;
      height: 35px;
      width: 140px;
    }
  }

  .plp-categories-container {
    margin: 32px 0 0 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }

  .results-section {
    margin-bottom: $space-xl;
    min-height: 440px;

    @media (min-width: $screenSm) {
      min-height: 0;
    }
  }

  .cart-button__wrapper {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: $space-md;
    position: sticky;

    @media (min-width: $screenSm) {
      position: unset;

      .cart-button {
        max-width: 392px;
      }
    }
  }
}
