@import "../../shared/scss/base";

.base-modal-container,
.small-modal-container {
  .cds--modal {
    backdrop-filter: blur(5px);
    background: $overlay;
  }
  .cds--modal-content {
    overflow-x: hidden;
  }

  .has-image {
    position: relative;
  }

  .modal-header-img {
    display: block;
    max-width: 100%;
    width: 100%;
  }

  .cds--modal-close {
    right: 16px;
    z-index: 999;
  }

  .modal-body-content {
    margin: 32px 16px;
    padding-bottom: 32px;
  }

  .modal-body-pdp-content {
    margin: 32px 16px;
  }

  .modal-header-content {
    display: flex;
    column-gap: 8px;
    padding-bottom: 8px;
  }

  .cds--modal-footer,
  .bx--modal-content__text {
    padding: 16px;
    background-color: $Marshmallow;
    white-space: pre-wrap;
  }

  .cds--btn-set {
    display: inline-table;
  }

  .cds--modal-footer .cds--btn {
    max-height: 46px;
    align-items: center;
    max-width: 100%;
    display: flex;
    flex-direction: row;
  }

  .modal-close-btn {
    padding-top: 16px;
  }

  .img-header-content {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 100%;
    text-align: left;

    > h1,
    > h2,
    > p {
      color: $text-on-color;
    }
  }

  .without-imgHeader-content {
    padding: 32px 0 16px 16px;
  }

  .cds--modal-content .cds--modal-close {
    > svg {
      margin-top: -2px;
      margin-left: -2px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  .img-close-btn {
    color: $text-on-color;
  }

  button.cancelBtn.cds--btn.cds--btn--secondary {
    margin-top: 16px;
  }

  .cancelBtn.cds--btn.cds--btn--tertiary {
    margin: 16px 0;
    border: 1px solid $light-black;
  }
}

.base-modal-container {
  .cds--modal-container {
    border-radius: 16px;
    top: 6%;
    height: 94% ;
    width: 95%;
    background-color: $Marshmallow;

    @media screen and (min-width: $screenSm) {
      height: 70%;
      width: 596px;
    }
  }
}

.small-modal-container {
  .cds--modal-container {
    border-radius: 16px;
    top: unset;
    height: auto;
    max-height: 95vh;
    width: 95%;
    background-color: $Marshmallow;

    @media screen and (min-width: $screenSm) {
      width: 596px;
    }
  }
}
