// Brands
$Spark: #ff5600;
$Green: #d1dc2f;
$Dark-Blue: #0d2b4f;
$Light-Blue: #d4e5f1;

// Neutrals
$Coal: #171725;
$light-black: #393939;
$Charcoal: #292938;
$Cinder: #525261;
$Foggy: #c4c4ca;
$CoralGrey: #f4f4f4;
$Sliver: #c6c6c6;
$Ash: #c4c4ca;
$Smoke: #f5f5f6;
$Marshmallow: #ffffff;

// Semantics
$Error: #931621;
$Success: #3e8914;
$Alert: #eab700;
$Information: #46237a;
$Interactive: #384aff;

// Transparencies
$Marshmallow-0: #ffffff00;
$Coal-40: #17172566;

// Background Tokens
$background: $Marshmallow;
$background-active: $Ash;
$background-hover: $Smoke;
$background-selected: $Ash;
$background-brand: $Spark;
$background-inverse: $Coal;
$background-inverse-hover: $Charcoal;

// Support Tokens
$support-error: $Error;
$support-success: $Success;
$support-success: $Alert;
$support-info: $Information;
$support-error-inverse: #f4b4b9;
$support-error-inverse: #9de7bf;
$support-error-inverse: #ffe485;
$support-info-inverse: #c9b4e9;

// Border Tokens
$border-subtle-00: $Smoke;
$border-subtle-01: $Ash;
$border-subtle-selected-01: $Coal;
$border-subtle-hover-01: $Coal;
$border-subtle-02: $Ash;
$border-subtle-selected-02: $Coal;
$border-subtle-hover-02: $Coal;
$border-strong-01: $Coal;
$border-strong-02: $Coal;
$border-strong-03: $Coal;
$border-interactive: $Spark;
$border-disabled: $Ash;
$border-tile-01: $Ash;
$border-tile-02: $Smoke;
$border-tile-03: $Charcoal;
$border-error: $Error;

// Layer Tokens
$layer-01: $Smoke;
$layer-hover-01: $Ash;
$layer-02: $Marshmallow;
$layer-03: $Ash;
$layer-inverse-01: $Coal;

// Field Tokens
$field-01: $Marshmallow;
$field-hover-01: $Marshmallow;

// Text Tokens
$text-primary: $Coal;
$text-secondary: $Cinder;
$text-placeholder: $Ash;
$text-on-color: $Marshmallow;
$text-helper: $Cinder;
$text-error: $Error;
$text-inverse: $Marshmallow;
$text-disabled: $Ash;

// Link Tokens
$link-primary: #384aff;
$link-primary-hover: #384aff;
$link-secondary: #384aff;
$link-inverse: #00e7ff;
$link-visited: #46237a;
$link-active: #171725;

// Button Tokens
$button-primary: $Coal;
$button-primary-hover: $Charcoal;
$button-primary-active: $Cinder;
$button-secondary: $Marshmallow-0;
$button-secondary-hover: $Smoke;
$button-secondary-active: $Ash;
$button-tertiary: $Marshmallow;
$button-tertiary-hover: $Smoke;
$button-tertiary-active: $Ash;
$button-ghost: $Marshmallow-0;
$button-ghost-hover: $Smoke;
$button-ghost-active: $Ash;
$button-neutral: $Marshmallow;
$button-neutral-hover: $Smoke;
$button-neutral-active: $Ash;
$button-disabled: $Ash;
$button-border: $Coal;

// Miscellaneous Tokens
$overlay: $Coal-40;
$interactive: $Spark;
$color-orange: #fe6100;
$color-grey: #5a5f65;
$color-cinnabar-red: #e74c3c;