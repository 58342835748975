@import "./../../shared/scss/base";

.checkbox-wrapper {
  .checkbox {
    gap: 8px;
  }

  p {
    align-self: center;
    display: flex;
  }
}
