@mixin media($query, $type: 'screen') {
  $media-query: '#{$type} and #{$query}';

  @media #{$media-query} {
    @content;
  }
}

@function rem($size) {
  $basePxSize: 16;
  $remSize: $size / $basePxSize;
  @return #{$remSize}rem;
}
