@import "../../shared/scss/base";

.checkbox-container {
  display: flex;
  flex-direction: column;

  .checkbox {
    display: flex;
    flex-direction: row;

    .cds--checkbox-wrapper {
      margin: 0;

      .cds--checkbox-label {
        color: $Coal;
        padding-left: 16px;

        &::before {
          border: 2px solid $Coal;
        }
      }

      .cds--checkbox:checked + .cds--checkbox-label::before {
        background-color: $Spark !important;
        border: none;
      }

      input:checked:disabled + .cds--checkbox-label::before {
        background-color: $Ash !important;
      }

      &.cds--checkbox-wrapper--invalid > label::before {
        border: 2px solid $Error !important;
      }

      input[type="checkbox"][data-invalid="true"]:checked + label::before {
        background-color: $Error !important;
        border: none !important;
      }

      .cds--checkbox__invalid-icon {
        display: none;
      }
    }
  }
  .checkbox-error {
    color: $Error;
    padding-top: 4px;
  }
}
