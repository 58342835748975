@import "../../shared/scss/base";
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
}

.loadingContent {
  margin-top: 16px;
}

.loading-circle {
  width: 72px;
  height: 72px;
  border: 8px solid $color-cinnabar-red;
  border-radius: 50%;
  border-top: 8px solid transparent;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
