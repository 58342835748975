@import "../../shared/scss/base";

.cds--link.link {
  font-style: normal;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  > p {
    color: $link-primary;
  }
  > svg {
    margin-left: 12px;
    color: $link-primary;
    height: 24px;
    width: 24px;
  }

  &:hover {
    cursor: pointer;
    text-decoration-line: underline;

    > p,
    > svg {
      color: $link-primary-hover;
    }
  }

  &:focus {
    outline: 1px solid $link-primary;
    text-decoration: none;

    > p,
    > svg {
      color: $link-primary;
    }
  }

  &:active {
    > p,
    > svg {
      color: $link-active;
    }
  }

  &.link-visited {
    > p,
    > svg {
      color: $link-visited;
    }
  }
}

.link-visited p {
  color: $link-visited;
}

.link.cds--link--disabled,
.link.cds--link--disabled:hover {
  @extend .cds--link;
  text-decoration-line: none;
  cursor: default;
  > p,
  > svg {
    color: $text-disabled;
  }
}
