@import "../../shared/scss/base";

.displayDesktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $Marshmallow;
  position: sticky;
  top: 0;
  z-index: 999;

  nav {
    display: flex;
    height: 78px;
    @media screen and (min-width: $screenSm) {
      padding: 0 32px;
    }
  }

  a {
    line-height: 0;
    align-self: center;
    text-decoration: none;
    border-bottom: 4px solid transparent;
    &.button-link {
      max-width: max-content;
      margin-right: 32px;
      border-bottom: none;
    }
    &.logo {
      border-bottom: none;
    }
  }

  .menu a {
    &.active {
      border-bottom: 4px solid $Spark;
    }
  }

  .menu {
    display: flex;
    padding-left: 24px;
    margin: 0;

    a {
      padding: 4px 24px 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      height: 100%;

      &:focus-visible,
      &:focus {
        outline: 2px solid $Dark-Blue;
      }
      &:hover {
        border-bottom: 4px solid $Ash;
      }
    }
  }
}

.displayMobile {
  position: sticky;
  top: 0;
  z-index: 999;
  height: 56px;

  .mobile-nav .menu {
    padding: 16px;
  }
}
