$content-max-width: 1200px;
$content-max-width-xl: 1600px;

$minimal-layout-footer-min-height-xs: 227px;
$minimal-layout-footer-min-height-sm: 223px;
$minimal-layout-footer-min-height-md: 174px;

$mobile-carousel-height: 300px;
$desktop-carousel-height: 370px;

// Space
$space-xxs:  4px;
$space-xs:   8px;
$space-sm:   12px;
$space-md:   16px;
$space-lg:   32px;
$space-xl:   64px;
$space-xxl:  128px;

// Line height
$line-height-xxs:  4px;
$line-height-xs:   8px;
$line-height-sm:   12px;
$line-height-md:   16px;
$line-height-lg:   32px;
$line-height-xl:   64px;
$line-height-xxl:  128px;

// Border Radius
$border-radius-sm: 4px;
$border-radius-base: 8px;
$border-radius-md: 16px;

// Shadow
$modal-shadow-color: rgba(16, 24, 64, 0.08);
