@import "./../../shared/scss/base";

.filter-options__wrapper {
  align-items: flex-start;
  border-bottom: 1px solid $Smoke;
  display: flex;
  gap: $space-md;
  padding: $space-md;

  .filter-option {
    color: $Coal;
    display: flex;
    flex-direction: column;
    height: 35px;
    justify-content: center;
    width: 60px;
  }

  .filter-tooltips__wrapper {
    display: flex;
    gap: $space-xs;

    .filter-tooltip {
      background: $Smoke;
      cursor: pointer;
      border-radius: $border-radius-base;
      padding: $space-xs $space-md;
    }
  }

  @media (min-width: $screenSm) {
    margin-left: $space-md;
    min-width: 410px;
    padding-left: 0;
    width: fit-content;
  }
}
